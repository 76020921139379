export const CommonAnalysisRefreshFrequency = () => import('../../components/Common/AnalysisRefreshFrequency.vue' /* webpackChunkName: "components/common-analysis-refresh-frequency" */).then(c => wrapFunctional(c.default || c))
export const AuthChangePassword = () => import('../../components/Auth/ChangePassword/ChangePassword.vue' /* webpackChunkName: "components/auth-change-password" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/Auth/Login/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthLogout = () => import('../../components/Auth/Logout/Logout.vue' /* webpackChunkName: "components/auth-logout" */).then(c => wrapFunctional(c.default || c))
export const AuthRegistration = () => import('../../components/Auth/Registration/Registration.vue' /* webpackChunkName: "components/auth-registration" */).then(c => wrapFunctional(c.default || c))
export const AuthResetEmailPassword = () => import('../../components/Auth/ResetPassword/ResetEmailPassword.vue' /* webpackChunkName: "components/auth-reset-email-password" */).then(c => wrapFunctional(c.default || c))
export const AuthResetPassword = () => import('../../components/Auth/ResetPassword/ResetPassword.vue' /* webpackChunkName: "components/auth-reset-password" */).then(c => wrapFunctional(c.default || c))
export const CommonCardsCardHorizontal = () => import('../../components/Common/Cards/CardHorizontal.vue' /* webpackChunkName: "components/common-cards-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const CommonChartsChart = () => import('../../components/Common/Charts/Chart.vue' /* webpackChunkName: "components/common-charts-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonCommentsCommentBox = () => import('../../components/Common/Comments/CommentBox.vue' /* webpackChunkName: "components/common-comments-comment-box" */).then(c => wrapFunctional(c.default || c))
export const CommonCommentsCommentForm = () => import('../../components/Common/Comments/CommentForm.vue' /* webpackChunkName: "components/common-comments-comment-form" */).then(c => wrapFunctional(c.default || c))
export const CommonComments = () => import('../../components/Common/Comments/Comments.vue' /* webpackChunkName: "components/common-comments" */).then(c => wrapFunctional(c.default || c))
export const CommonFilters = () => import('../../components/Common/Filters/Filters.vue' /* webpackChunkName: "components/common-filters" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersAdvanced = () => import('../../components/Common/Filters/FiltersAdvanced.vue' /* webpackChunkName: "components/common-filters-advanced" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersAuthors = () => import('../../components/Common/Filters/FiltersAuthors.vue' /* webpackChunkName: "components/common-filters-authors" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersClients = () => import('../../components/Common/Filters/FiltersClients.vue' /* webpackChunkName: "components/common-filters-clients" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersIndustries = () => import('../../components/Common/Filters/FiltersIndustries.vue' /* webpackChunkName: "components/common-filters-industries" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersProjects = () => import('../../components/Common/Filters/FiltersProjects.vue' /* webpackChunkName: "components/common-filters-projects" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersTags = () => import('../../components/Common/Filters/FiltersTags.vue' /* webpackChunkName: "components/common-filters-tags" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersViews = () => import('../../components/Common/Filters/FiltersViews.vue' /* webpackChunkName: "components/common-filters-views" */).then(c => wrapFunctional(c.default || c))
export const CommonListsList = () => import('../../components/Common/Lists/List.vue' /* webpackChunkName: "components/common-lists-list" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuOther = () => import('../../components/Common/Menu/MenuOther.vue' /* webpackChunkName: "components/common-menu-other" */).then(c => wrapFunctional(c.default || c))
export const CommonLogo = () => import('../../components/Common/Logo/Logo.vue' /* webpackChunkName: "components/common-logo" */).then(c => wrapFunctional(c.default || c))
export const CommonOnHold = () => import('../../components/Common/OnHold/OnHold.vue' /* webpackChunkName: "components/common-on-hold" */).then(c => wrapFunctional(c.default || c))
export const CommonOptionsPopover = () => import('../../components/Common/OptionsPopover/OptionsPopover.vue' /* webpackChunkName: "components/common-options-popover" */).then(c => wrapFunctional(c.default || c))
export const CommonSidebar = () => import('../../components/Common/Sidebar/Sidebar.vue' /* webpackChunkName: "components/common-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CommonPagination = () => import('../../components/Common/Pagination/Pagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonSlidesSlideBox = () => import('../../components/Common/Slides/SlideBox.vue' /* webpackChunkName: "components/common-slides-slide-box" */).then(c => wrapFunctional(c.default || c))
export const CommonSlidesList = () => import('../../components/Common/Slides/SlidesList.vue' /* webpackChunkName: "components/common-slides-list" */).then(c => wrapFunctional(c.default || c))
export const CommonTagsTag = () => import('../../components/Common/Tags/Tag.vue' /* webpackChunkName: "components/common-tags-tag" */).then(c => wrapFunctional(c.default || c))
export const CommonTagsList = () => import('../../components/Common/Tags/TagsList.vue' /* webpackChunkName: "components/common-tags-list" */).then(c => wrapFunctional(c.default || c))
export const ContentGraphManagerFiltersAdvanced = () => import('../../components/ContentGraphManager/Filters/FiltersAdvanced.vue' /* webpackChunkName: "components/content-graph-manager-filters-advanced" */).then(c => wrapFunctional(c.default || c))
export const ContentGraphManagerSearch = () => import('../../components/ContentGraphManager/Search/Search.vue' /* webpackChunkName: "components/content-graph-manager-search" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalyses = () => import('../../components/Panel/Analyses/Analyses.vue' /* webpackChunkName: "components/panel-analyses" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysisBox = () => import('../../components/Panel/Analysis/AnalysisBox.vue' /* webpackChunkName: "components/panel-analysis-box" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysisList = () => import('../../components/Panel/Analysis/AnalysisList.vue' /* webpackChunkName: "components/panel-analysis-list" */).then(c => wrapFunctional(c.default || c))
export const PanelConclusionsList = () => import('../../components/Panel/Conclusions/ConclusionsList.vue' /* webpackChunkName: "components/panel-conclusions-list" */).then(c => wrapFunctional(c.default || c))
export const PanelClientsClientBox = () => import('../../components/Panel/Clients/ClientBox.vue' /* webpackChunkName: "components/panel-clients-client-box" */).then(c => wrapFunctional(c.default || c))
export const PanelClientsList = () => import('../../components/Panel/Clients/ClientsList.vue' /* webpackChunkName: "components/panel-clients-list" */).then(c => wrapFunctional(c.default || c))
export const PanelDashboardChanges = () => import('../../components/Panel/Dashboard/DashboardChanges.vue' /* webpackChunkName: "components/panel-dashboard-changes" */).then(c => wrapFunctional(c.default || c))
export const PanelDashboardPositions = () => import('../../components/Panel/Dashboard/DashboardPositions.vue' /* webpackChunkName: "components/panel-dashboard-positions" */).then(c => wrapFunctional(c.default || c))
export const PanelDashboardReports = () => import('../../components/Panel/Dashboard/DashboardReports.vue' /* webpackChunkName: "components/panel-dashboard-reports" */).then(c => wrapFunctional(c.default || c))
export const PanelDashboardResults = () => import('../../components/Panel/Dashboard/DashboardResults.vue' /* webpackChunkName: "components/panel-dashboard-results" */).then(c => wrapFunctional(c.default || c))
export const PanelProjectsProjectBox = () => import('../../components/Panel/Projects/ProjectBox.vue' /* webpackChunkName: "components/panel-projects-project-box" */).then(c => wrapFunctional(c.default || c))
export const PanelProjectsList = () => import('../../components/Panel/Projects/ProjectsList.vue' /* webpackChunkName: "components/panel-projects-list" */).then(c => wrapFunctional(c.default || c))
export const PanelReportsReportBox = () => import('../../components/Panel/Reports/ReportBox.vue' /* webpackChunkName: "components/panel-reports-report-box" */).then(c => wrapFunctional(c.default || c))
export const PanelReportsList = () => import('../../components/Panel/Reports/ReportsList.vue' /* webpackChunkName: "components/panel-reports-list" */).then(c => wrapFunctional(c.default || c))
export const PanelSimulationsSimulationBox = () => import('../../components/Panel/Simulations/SimulationBox.vue' /* webpackChunkName: "components/panel-simulations-simulation-box" */).then(c => wrapFunctional(c.default || c))
export const PanelSimulationsList = () => import('../../components/Panel/Simulations/SimulationsList.vue' /* webpackChunkName: "components/panel-simulations-list" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersUserBox = () => import('../../components/Panel/Users/UserBox.vue' /* webpackChunkName: "components/panel-users-user-box" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersList = () => import('../../components/Panel/Users/UsersList.vue' /* webpackChunkName: "components/panel-users-list" */).then(c => wrapFunctional(c.default || c))
export const ElementUIDialog = () => import('../../components/ElementUI/Dialog/Dialog.vue' /* webpackChunkName: "components/element-u-i-dialog" */).then(c => wrapFunctional(c.default || c))
export const TFIDFFaq = () => import('../../components/TFIDF/Faq/Faq.vue' /* webpackChunkName: "components/t-f-i-d-f-faq" */).then(c => wrapFunctional(c.default || c))
export const TFIDFFaqList = () => import('../../components/TFIDF/Faq/FaqList.vue' /* webpackChunkName: "components/t-f-i-d-f-faq-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenu = () => import('../../components/WhiteCat/Menu/Menu.vue' /* webpackChunkName: "components/white-cat-menu" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenuMain = () => import('../../components/WhiteCat/Menu/MenuMain.vue' /* webpackChunkName: "components/white-cat-menu-main" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenuProjects = () => import('../../components/WhiteCat/Menu/MenuProjects.vue' /* webpackChunkName: "components/white-cat-menu-projects" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenuReports = () => import('../../components/WhiteCat/Menu/MenuReports.vue' /* webpackChunkName: "components/white-cat-menu-reports" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenuSubmenu = () => import('../../components/WhiteCat/Menu/Submenu.vue' /* webpackChunkName: "components/white-cat-menu-submenu" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisAnalysesListItem = () => import('../../components/WhiteDog/Analysis/AnalysesListItem.vue' /* webpackChunkName: "components/white-dog-analysis-analyses-list-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisAnalysesListItemBox = () => import('../../components/WhiteDog/Analysis/AnalysesListItemBox.vue' /* webpackChunkName: "components/white-dog-analysis-analyses-list-item-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisAnalysesListProject = () => import('../../components/WhiteDog/Analysis/AnalysesListProject.vue' /* webpackChunkName: "components/white-dog-analysis-analyses-list-project" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisDomains = () => import('../../components/WhiteDog/Analysis/AnalysisDomains.vue' /* webpackChunkName: "components/white-dog-analysis-domains" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisList = () => import('../../components/WhiteDog/Analysis/AnalysisList.vue' /* webpackChunkName: "components/white-dog-analysis-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogAnalysisRanking = () => import('../../components/WhiteDog/Analysis/AnalysisRanking.vue' /* webpackChunkName: "components/white-dog-analysis-ranking" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogMenu = () => import('../../components/WhiteDog/Menu/Menu.vue' /* webpackChunkName: "components/white-dog-menu" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogMenuMain = () => import('../../components/WhiteDog/Menu/MenuMain.vue' /* webpackChunkName: "components/white-dog-menu-main" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogMenuReports = () => import('../../components/WhiteDog/Menu/MenuReports.vue' /* webpackChunkName: "components/white-dog-menu-reports" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogMenuSubmenu = () => import('../../components/WhiteDog/Menu/Submenu.vue' /* webpackChunkName: "components/white-dog-menu-submenu" */).then(c => wrapFunctional(c.default || c))
export const CommonDialogsAnalysisCreateDialog = () => import('../../components/Common/Dialogs/Analysis/AnalysisCreateDialog.vue' /* webpackChunkName: "components/common-dialogs-analysis-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationBox = () => import('../../components/WhiteDog/Simulations/SimulationBox.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsList = () => import('../../components/WhiteDog/Simulations/SimulationsList.vue' /* webpackChunkName: "components/white-dog-simulations-list" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersAnalysis = () => import('../../components/Common/Filters/FiltersAnalysis/FiltersAnalysis.vue' /* webpackChunkName: "components/common-filters-analysis" */).then(c => wrapFunctional(c.default || c))
export const CommonFiltersAnalysisAdvanced = () => import('../../components/Common/Filters/FiltersAnalysis/FiltersAnalysisAdvanced.vue' /* webpackChunkName: "components/common-filters-analysis-advanced" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesDialogAnalysisDialogForm = () => import('../../components/Panel/Analyses/Dialog/AnalysisDialogForm.vue' /* webpackChunkName: "components/panel-analyses-dialog-analysis-dialog-form" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesDialogEditAnalysisDialogForm = () => import('../../components/Panel/Analyses/Dialog/EditAnalysisDialogForm.vue' /* webpackChunkName: "components/panel-analyses-dialog-edit-analysis-dialog-form" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesDialogPreparingAnalysisDialog = () => import('../../components/Panel/Analyses/Dialog/PreparingAnalysisDialog.vue' /* webpackChunkName: "components/panel-analyses-dialog-preparing-analysis-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesDialogRunAnalysisDialog = () => import('../../components/Panel/Analyses/Dialog/RunAnalysisDialog.vue' /* webpackChunkName: "components/panel-analyses-dialog-run-analysis-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesDialogTooBigFile = () => import('../../components/Panel/Analyses/Dialog/TooBigFile.vue' /* webpackChunkName: "components/panel-analyses-dialog-too-big-file" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysisAssignToReportDialog = () => import('../../components/Panel/Analysis/AssignToReport/AssignToReportDialog.vue' /* webpackChunkName: "components/panel-analysis-assign-to-report-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesList = () => import('../../components/Panel/Analyses/Partials/AnalysesList.vue' /* webpackChunkName: "components/panel-analyses-list" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesListExpandedRowContent = () => import('../../components/Panel/Analyses/Partials/AnalysesListExpandedRowContent.vue' /* webpackChunkName: "components/panel-analyses-list-expanded-row-content" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesListExpandedRowContentAnalysisBox = () => import('../../components/Panel/Analyses/Partials/AnalysesListExpandedRowContentAnalysisBox.vue' /* webpackChunkName: "components/panel-analyses-list-expanded-row-content-analysis-box" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesListExpandedRowMoreOptions = () => import('../../components/Panel/Analyses/Partials/AnalysesListExpandedRowMoreOptions.vue' /* webpackChunkName: "components/panel-analyses-list-expanded-row-more-options" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysesPartialsArchivalResources = () => import('../../components/Panel/Analyses/Partials/ArchivalResources.vue' /* webpackChunkName: "components/panel-analyses-partials-archival-resources" */).then(c => wrapFunctional(c.default || c))
export const PanelClientsClientCreateDialog = () => import('../../components/Panel/Clients/ClientCreate/ClientCreateDialog.vue' /* webpackChunkName: "components/panel-clients-client-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelAnalysisTagCreateDialog = () => import('../../components/Panel/Analysis/TagCreate/TagCreateDialog.vue' /* webpackChunkName: "components/panel-analysis-tag-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelReportsReportCreateDialog = () => import('../../components/Panel/Reports/ReportCreate/ReportCreateDialog.vue' /* webpackChunkName: "components/panel-reports-report-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelProjectsProjectCreate = () => import('../../components/Panel/Projects/ProjectCreate/ProjectCreate.vue' /* webpackChunkName: "components/panel-projects-project-create" */).then(c => wrapFunctional(c.default || c))
export const PanelProjectsProjectCreateDialog = () => import('../../components/Panel/Projects/ProjectCreate/ProjectCreateDialog.vue' /* webpackChunkName: "components/panel-projects-project-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelSimulationsSimulationFilters = () => import('../../components/Panel/Simulations/SimulationFilters/SimulationFilters.vue' /* webpackChunkName: "components/panel-simulations-simulation-filters" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersDialogUserDialogForm = () => import('../../components/Panel/Users/Dialog/UserDialogForm.vue' /* webpackChunkName: "components/panel-users-dialog-user-dialog-form" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersUserCreate = () => import('../../components/Panel/Users/UserCreate/UserCreate.vue' /* webpackChunkName: "components/panel-users-user-create" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersUserCreateDialog = () => import('../../components/Panel/Users/UserCreate/UserCreateDialog.vue' /* webpackChunkName: "components/panel-users-user-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersUserEditDialog = () => import('../../components/Panel/Users/UserEdit/UserEditDialog.vue' /* webpackChunkName: "components/panel-users-user-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const PanelUsersUserResetPasswordDialog = () => import('../../components/Panel/Users/UserResetPassword/UserResetPasswordDialog.vue' /* webpackChunkName: "components/panel-users-user-reset-password-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysesContainer = () => import('../../components/WhiteCat/Panel/Analyses/AnalysesContainer.vue' /* webpackChunkName: "components/white-cat-panel-analyses-container" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelClientsClientBox = () => import('../../components/WhiteCat/Panel/Clients/ClientBox.vue' /* webpackChunkName: "components/white-cat-panel-clients-client-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelClientsList = () => import('../../components/WhiteCat/Panel/Clients/ClientsList.vue' /* webpackChunkName: "components/white-cat-panel-clients-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisContainer = () => import('../../components/WhiteCat/Panel/Analysis/AnalysisContainer.vue' /* webpackChunkName: "components/white-cat-panel-analysis-container" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisDetails = () => import('../../components/WhiteCat/Panel/Analysis/AnalysisDetails.vue' /* webpackChunkName: "components/white-cat-panel-analysis-details" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisList = () => import('../../components/WhiteCat/Panel/Analysis/AnalysisList.vue' /* webpackChunkName: "components/white-cat-panel-analysis-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelProjectsProjectBox = () => import('../../components/WhiteCat/Panel/Projects/ProjectBox.vue' /* webpackChunkName: "components/white-cat-panel-projects-project-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelProjectsList = () => import('../../components/WhiteCat/Panel/Projects/ProjectsList.vue' /* webpackChunkName: "components/white-cat-panel-projects-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysesListItem = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysesListItem.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analyses-list-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysesListItemBox = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysesListItemBox.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analyses-list-item-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysesListProject = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysesListProject.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analyses-list-project" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysis = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysis.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analysis" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysisDialogForm = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysisDialogForm.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analysis-dialog-form" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationAnalysisList = () => import('../../components/WhiteDog/Simulations/SimulationAnalysis/SimulationAnalysisList.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-analysis-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationFilters = () => import('../../components/WhiteDog/Simulations/SimulationFilters/SimulationFilters.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-filters" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationScenario = () => import('../../components/WhiteDog/Simulations/SimulationScenario/SimulationScenario.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-scenario" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogSimulationsSimulationScenarioItem = () => import('../../components/WhiteDog/Simulations/SimulationScenario/SimulationScenarioItem.vue' /* webpackChunkName: "components/white-dog-simulations-simulation-scenario-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatCommonDialogAnalysisCreateDialog = () => import('../../components/WhiteCat/Common/Dialog/Analysis/AnalysisCreateDialog.vue' /* webpackChunkName: "components/white-cat-common-dialog-analysis-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatCommonDialogAnalysisPreparingAnalysisDialog = () => import('../../components/WhiteCat/Common/Dialog/Analysis/PreparingAnalysisDialog.vue' /* webpackChunkName: "components/white-cat-common-dialog-analysis-preparing-analysis-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatMenuSegmentsSegmentAddDialog = () => import('../../components/WhiteCat/Menu/Segments/SegmentAdd/SegmentAddDialog.vue' /* webpackChunkName: "components/white-cat-menu-segments-segment-add-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysesList = () => import('../../components/WhiteCat/Panel/Analyses/Partials/AnalysesList.vue' /* webpackChunkName: "components/white-cat-panel-analyses-list" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysesListItem = () => import('../../components/WhiteCat/Panel/Analyses/Partials/AnalysesListItem.vue' /* webpackChunkName: "components/white-cat-panel-analyses-list-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysesListItemBox = () => import('../../components/WhiteCat/Panel/Analyses/Partials/AnalysesListItemBox.vue' /* webpackChunkName: "components/white-cat-panel-analyses-list-item-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysesListProjectItem = () => import('../../components/WhiteCat/Panel/Analyses/Partials/AnalysesListProjectItem.vue' /* webpackChunkName: "components/white-cat-panel-analyses-list-project-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelClientsClientCreateDialog = () => import('../../components/WhiteCat/Panel/Clients/ClientCreate/ClientCreateDialog.vue' /* webpackChunkName: "components/white-cat-panel-clients-client-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisDetailsBox = () => import('../../components/WhiteCat/Panel/Analysis/UIPartials/AnalysisDetailsBox.vue' /* webpackChunkName: "components/white-cat-panel-analysis-details-box" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisDetailsGroup = () => import('../../components/WhiteCat/Panel/Analysis/UIPartials/AnalysisDetailsGroup.vue' /* webpackChunkName: "components/white-cat-panel-analysis-details-group" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisDetailsItem = () => import('../../components/WhiteCat/Panel/Analysis/UIPartials/AnalysisDetailsItem.vue' /* webpackChunkName: "components/white-cat-panel-analysis-details-item" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisDetailsLoader = () => import('../../components/WhiteCat/Panel/Analysis/UIPartials/AnalysisDetailsLoader.vue' /* webpackChunkName: "components/white-cat-panel-analysis-details-loader" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelAnalysisPreviewRow = () => import('../../components/WhiteCat/Panel/Analysis/UIPartials/AnalysisPreviewRow.vue' /* webpackChunkName: "components/white-cat-panel-analysis-preview-row" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelProjectsProjectCreate = () => import('../../components/WhiteCat/Panel/Projects/ProjectCreate/ProjectCreate.vue' /* webpackChunkName: "components/white-cat-panel-projects-project-create" */).then(c => wrapFunctional(c.default || c))
export const WhiteCatPanelProjectsProjectCreateDialog = () => import('../../components/WhiteCat/Panel/Projects/ProjectCreate/ProjectCreateDialog.vue' /* webpackChunkName: "components/white-cat-panel-projects-project-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const WhiteDogMenuSegmentsSegmentAddDialog = () => import('../../components/WhiteDog/Menu/Segments/SegmentAdd/SegmentAddDialog.vue' /* webpackChunkName: "components/white-dog-menu-segments-segment-add-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
